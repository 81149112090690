// Import theme overrides
@import "global";
@import "components/sidenav";
@import "sections/resume-section";

// Import custom styles
@import "components/buttons";
@import "components/icons";
@import "components/logos";
@import "components/switch";
