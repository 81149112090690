.social-icons {
  display: flex;
  .social-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(md) {
      height: 3rem;
      width: 3rem;
      line-height: 3rem;
      margin-right: 0.5rem;
    }
    i.logo {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.skill-icons {
  &.list-inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .list-inline-item {
      align-self: center;
    }
  }

  &.skill-icons-large {
    &.list-inline {
      margin-bottom: 0.2rem;
      .list-inline-item {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  &.skill-icons-small {
    &.list-inline {
      .list-inline-item {
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
      }
    }
  }
}

div.skill-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-700;
  color: $white;
  cursor: default;
  &:hover {
    background-color: $primary;
  }
  span {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
  }

  &.skill-icon-0 {
    font-size: 1rem;
    padding: 2px 7px;
    border-radius: 7px;
    i {
      &.logo {
        width: 1rem;
        height: 1rem;
      }
      & + span {
        margin-left: 4px;
      }
    }
  }
  &.skill-icon-1 {
    font-size: 1.25rem;
    padding: 3px 8.5px;
    border-radius: 8.5px;
    i {
      &.logo {
        width: 1.25rem;
        height: 1.25rem;
      }
      & + span {
        margin-left: 6px;
      }
    }
  }
  &.skill-icon-2 {
    font-size: 1.5rem;
    padding: 4px 10px;
    border-radius: 10px;
    i {
      &.logo {
        width: 1.5rem;
        height: 1.5rem;
      }
      & + span {
        margin-left: 8px;
      }
    }
  }
  &.skill-icon-3 {
    font-size: 1.75rem;
    padding: 5px 11.5px;
    border-radius: 11.5px;
    i {
      &.logo {
        width: 1.75rem;
        height: 1.75rem;
      }
      & + span {
        margin-left: 10px;
      }
    }
  }
  &.skill-icon-4 {
    font-size: 2rem;
    padding: 6px 13px;
    border-radius: 13px;
    i {
      &.logo {
        width: 2rem;
        height: 2rem;
      }
      & + span {
        margin-left: 12px;
      }
    }
  }
}

.activity-icons {
  color: $gray-700;
  font-size: 1.5rem;
}
