// Pre-state grayscale colors used in other variables

$gray-800: #343a40;

// Override Bootstrap color system

$orange: #bd5d38;

// Override Bootstrap default state colors

$primary: $orange;

// Override Bootstrap yiq lightness value

$yiq-contrasted-threshold: 205;
