button {
  &:focus {
    outline: none;
  }
}

.btn-primary {
  color: $white !important;
  background-color: $primary !important;
  border-color: $primary !important;
  &:hover {
    color: $white !important;
    background-color: darken($primary, 7.5%) !important;
    border-color: darken($primary, 10%) !important;
  }
  &:focus, &:active:focus {
    box-shadow: 0 0 0 .2rem rgba($primary, .5) !important;
  }
}

.form-control {
  &:focus {
    border-color: $gray-500;
    box-shadow: 0 0 0 .2rem rgba($gray-800, .25);
  }
}
