@charset "utf-8";

// Import fonts
@import url('https://fonts.googleapis.com/css?family=Saira+Extra+Condensed:500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:400,400i,800,800i&display=swap');

// Import FontAwesome
$fa-font-path: "../webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";

// Import theme
@import "startbootstrap-resume/src/scss/styles.scss";

// Import theme overrides and custom styles
@import "./custom/styles.scss";
