@include media-breakpoint-up(lg) {
  #sideNav {
    .navbar-brand {
      .img-profile {
        border: 0.25rem solid fade-out($white, 0.8);
      }
    }
    .navbar-collapse {
      flex-direction: column;
    }
  }
}

#sideNav {
  .navbar-nav {
    margin: 0;
    @include media-breakpoint-down(lg) {
      margin: 10px 0;
    }
  }
  .navbar-toggler {
    padding: .5rem .75rem;
    color: rgba($white, .5);
    border-color: rgba($white, .3);
    &:hover, &:focus {
      color: rgba($white, .75);
      border-color: rgba($white, .55);
      outline-color: none;
    }
  }
  .navbar-close-btn {
    color: rgba($white, .5);
    text-decoration: none;
    background-color: transparent;
    border: none;
    width: 100%;
    &:hover, &:focus {
      color: rgba($white, .75);
    }
  }
  a.legal-notice {
    color: rgba($white, .5);
    text-decoration: none;
    &:hover {
      color: rgba($white, .75);
    }
    @include media-breakpoint-down(lg) {
      margin-top: .25rem;
      display: block;
    }
  }
}
