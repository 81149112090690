// Import variables
@import "./variables.scss";

// Import Bootstrap
@import "bootstrap/scss/bootstrap.scss";

// Global CSS
@import "./global.scss";

// Components
@import "./components/sidenav.scss";
@import "./components/icons.scss";

// Sections
@import "./sections/resume-section.scss";
