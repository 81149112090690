section.resume-section {
  h3 {
    span {
      @include media-breakpoint-up(md) {
        &:before {
          content: ' · ';
        }
      }
    }
  }
  .subheading {
    @include media-breakpoint-down(sm) {
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
  }
  .resume-logo {
    display: inline-block;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    margin-bottom: 1rem;
    img:hover {
      filter: contrast(75%);
    }
  }
}
#experience {
  .resume-logo {
    img {
      max-width: 200px;
      max-height: 80px;
      @include media-breakpoint-down(sm) {
        max-width: 100px;
        max-height: 40px;
      }
    }
  }
}
#education {
  .resume-logo {
    img {
      max-width: 250px;
      max-height: 125px;
      @include media-breakpoint-down(sm) {
        max-width: 125px;
        max-height: 62.5px;
      }
    }
  }
}
