.logo {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &.lazyloaded {
    &.ansible {
      background-image: url(/assets/img/logo/skill/ansible.png);
    }
    &.bash {
      background-image: url(/assets/img/logo/skill/bash.png);
    }
    &.bulma {
      background-image: url(/assets/img/logo/skill/bulma.png);
    }
    &.cmsms {
      background-image: url(/assets/img/logo/skill/cmsms.png);
    }
    &.doctrine {
      background-image: url(/assets/img/logo/skill/doctrine.png);
    }
    &.elasticsearch {
      background-image: url(/assets/img/logo/skill/elasticsearch.png);
    }
    &.exalead {
      background-image: url(/assets/img/logo/skill/exalead.png);
    }
    &.ez {
      background-image: url(/assets/img/logo/skill/ez.png);
    }
    &.gatsby {
      background-image: url(/assets/img/logo/skill/gatsby.png);
    }
    &.genio {
      background-image: url(/assets/img/logo/skill/genio.png);
    }
    &.go {
      background-image: url(/assets/img/logo/skill/go.svg);
    }
    &.hugo {
      background-image: url(/assets/img/logo/skill/hugo.png);
    }
    &.jekyll {
      background-image: url(/assets/img/logo/skill/jekyll.png);
    }
    &.jquery {
      background-image: url(/assets/img/logo/skill/jquery.png);
    }
    &.malt {
      background-image: url(/assets/img/logo/skill/malt.png);
    }
    &.mariadb {
      background-image: url(/assets/img/logo/skill/mariadb.png);
    }
    &.materialize {
      background-image: url(/assets/img/logo/skill/materialize.png);
    }
    &.mysql {
      background-image: url(/assets/img/logo/skill/mysql.png);
    }
    &.nextjs {
      background-image: url(/assets/img/logo/skill/nextjs.png);
    }
    &.nuxtjs {
      background-image: url(/assets/img/logo/skill/nuxtjs.png);
    }
    &.oracle {
      background-image: url(/assets/img/logo/skill/oracle.png);
    }
    &.phpunit {
      background-image: url(/assets/img/logo/skill/phpunit.png);
    }
    &.postgres {
      background-image: url(/assets/img/logo/skill/postgres.png);
    }
    &.rabbit {
      background-image: url(/assets/img/logo/skill/rabbit.png);
    }
    &.redmine {
      background-image: url(/assets/img/logo/skill/redmine.png);
    }
    &.redux {
      background-image: url(/assets/img/logo/skill/redux.png);
    }
    &.simpletest {
      background-image: url(/assets/img/logo/skill/simpletest.png);
    }
    &.salt {
      background-image: url(/assets/img/logo/skill/salt.png);
    }
    &.solr {
      background-image: url(/assets/img/logo/skill/solr.png);
    }
    &.scrum {
      background-image: url(/assets/img/logo/skill/scrum.png);
    }
    &.svn {
      background-image: url(/assets/img/logo/skill/svn.png);
    }
    &.twig {
      background-image: url(/assets/img/logo/skill/twig.png);
    }
    &.varnish {
      background-image: url(/assets/img/logo/skill/varnish.png);
    }
    &.webpack {
      background-image: url(/assets/img/logo/skill/webpack.png);
    }
    &.xp {
      background-image: url(/assets/img/logo/skill/xp.png);
    }
    &.zf {
      background-image: url(/assets/img/logo/skill/zf.png);
    }
  }
}
