@font-face {
  font-family: $font-family-base;
  font-display: swap;
}

@font-face {
  font-family: $headings-font-family;
  font-display: swap;
}

h2 {
  line-height: 3.5rem;
}

h3 {
  line-height: 2rem;
}

.subheading {
  line-height: 1.5rem;
}

a {
  outline: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &.neutral {
    color: inherit;
  }
}
