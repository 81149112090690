.lang-switcher {
  color: rgba($white, .5);

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: rgba($white, .75);
    }
    @include media-breakpoint-down(md) {
      margin-top: .25rem;
      display: block;
    }
    @include media-breakpoint-up(lg) {
      &:not(:last-of-type) {
        &::after {
          content: '·';
          color: rgba($white, .5);
        }
      }
    }
  }
}
