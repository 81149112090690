// Restate and add to the Bootstrap default spacing variables
//
// The spacer is restated in order to add new entries to the $spacers map
// The 10 and n1 through n10 spacers are newly added allowing for larger
// spacing options and negative padding and margin utilities

$spacer: 1rem;

// Spacing variable to set the sidebar base width

$sidebar-base-width: 17rem;

// Spacing variable to add padding to the top of the body element that matches the height of the navbar

$navbar-base-height: 3.375rem;
